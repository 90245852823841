import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {CashFlowRowEntity} from "../entity/cash-flow-row.entity";
import {CashFlowRowOverdue, CashFlowRowResultDto} from "../dto/cash-flow-row-result.dto";
import {CashFlowRowAuditDto} from "../dto/cash-flow-row-audit.dto";
import {CashFlowCostCenterDto} from "../dto/cash-flow-cost-center.dto";
import {CashFlowAnalyticCostCenterDto} from "../dto/cash-flow-analytic-cost-center.dto";

export function useCashFlowRowRepository() {
  const httpService = usePlaneduHttpService<CashFlowRowEntity>('cash-flow-row');

  function moveUp(rowId: number): Promise<BaseResponse<any>> {
    return httpService.post(
      `${httpService.resourceName}/move-row`,
      JSON.stringify({ rowId, movement: -1 })
    )
  }

  function moveDown(rowId: number): Promise<BaseResponse<any>> {
    return httpService.post(
      `${httpService.resourceName}/move-row`,
      JSON.stringify({ rowId, movement: 1 })
    )
  }

  function generateReport(args: GenerateReportArgs): Promise<BaseResponse<CashFlowRowResultDto[]>> {
    return httpService.post(
      `${httpService.resourceName}/generate-report`,
      JSON.stringify(args)
    );
  }

  function exportReport(
    args: GenerateReportArgs
  ): Promise<BaseResponse<CashFlowRowResultDto[]>> {
    return httpService.downloadRequest(
      `post`,
      `${httpService.resourceName}/generate-report/export`,
      'cash-flow.xlsx',
      JSON.stringify(args),
    );
  }

  function auditReport(
    args: {
      cashFlowRowId: number,
      dateTime: string,
      firmId?: number,
      costCenterIds?: number[],
      bankAccountId?: number,
      dateFormat?: string,
    }): Promise<BaseResponse<CashFlowRowAuditDto[]>> {
    return httpService.post(
      `${httpService.resourceName}/generate-report/audit`,
      JSON.stringify(args)
    )
  }

  function auditOverdueCell(
    rowId: string | number
  ): Promise<BaseResponse<any>> {
    return httpService.post(
      `${httpService.resourceName}/generate-overdue-cash-flow/audit/${rowId}`
    )
  }
  
  function exportAuditReport(
    args: {
      cashFlowRowId: number,
      dateTime: string,
      dateFormat?: string,
      costCenterIds?: number[],
      firmId?: number,
      bankAccountId?: number,
    }): Promise<BaseResponse<CashFlowRowAuditDto[]>> {
    return httpService.downloadRequest(
      `post`,
      `${httpService.resourceName}/generate-report/audit/export`,
      `auditoria-fluxo-de-caixe.xlsx`,
      JSON.stringify(args)
    )
  }

  function generateCCReport(
    args: { startDate: string, endDate: string, dateFormat: string }
  ) : Promise<BaseResponse<CashFlowCostCenterDto[]>> {
    return httpService.post(
      `${httpService.resourceName}/generate-cc-report`,
      JSON.stringify(args),
      undefined,
    )
  }

  function generateCCAReport(
    args: { startDate: string, endDate: string, dateFormat: string }
  ) : Promise<BaseResponse<CashFlowAnalyticCostCenterDto[]>> {
    return httpService.post(
      `${httpService.resourceName}/generate-cca-report`,
      JSON.stringify(args),
      undefined,
    )
  }

  function exportCCReport(
    args: { startDate: string, endDate: string, removeAnalyticCostCenters: boolean, dateFormat: string  }
  ) : Promise<BaseResponse<CashFlowCostCenterDto[]>> {
    const { removeAnalyticCostCenters } = args;
    return httpService.downloadRequest(
      `post`,
      `${httpService.resourceName}/generate-cc-report/export`,
      `cost-center-report.xlsx`,
      JSON.stringify(args),
      [{ key: `removeAnalyticCostCenters`, value: removeAnalyticCostCenters}]
    )
  }

  function exportCCAReport(
    args: { startDate: string, endDate: string, dateFormat: string  }
  ) : Promise<BaseResponse<CashFlowCostCenterDto[]>> {
    return httpService.downloadRequest(
      `post`,
      `${httpService.resourceName}/generate-cca-report/export`,
      `analytic-cost-center-report.xlsx`,
      JSON.stringify(args),
    )
  }

  function getOverdueCashFlowRows(): Promise<BaseResponse<CashFlowRowOverdue[]>> {
    return httpService.post(
      `${httpService.resourceName}/generate-overdue-cash-flow`,
    )
  }

  return {
    ...httpService,
    moveUp,
    moveDown,
    generateReport,
    exportReport,
    auditReport,
    exportAuditReport,
    generateCCReport,
    exportCCReport,
    generateCCAReport,
    exportCCAReport,
    getOverdueCashFlowRows,
    auditOverdueCell
  }
}

export type GenerateReportArgs = {
  startDate: string,
  endDate: string,
  firmId?: number,
  costCenterIds?: number[],
  bankAccountId?: number,
  dateFormat: 'd' | 'm' | 'y',
}
