import {BaseResponse, usePlaneduHttpService} from "../service/planedu.http.service";
import {ReportFilterEntity} from "../entity/report-filter.entity";

export function useReportFilterRepository() {
  const httpService = usePlaneduHttpService<ReportFilterEntity>('report-filter')

  function listFiltersByEntity(entity: string): Promise<BaseResponse<ReportFilterEntity[]>> {
    return httpService.get(
      `${httpService.resourceName}/entity/${entity}`
    )
  }
  return {
    ...httpService,
    listFiltersByEntity
  }
}
