import {ColumnsType} from "antd/lib/table";
import {Button, Table} from "antd";
import {useEffect, useState} from "react";
import {useCashFlowRowRepository} from "../../../../domain/repository/cash-flow-row.repository";
import {EyeOutlined} from "@ant-design/icons";

export function OverdueCellAuditListComponent(props: {
  rowId: number | string,
  onRowSelected: (row: any) => any
}) {
  const [data, setData] = useState<any[]>([]);
  const cashFlowRepo = useCashFlowRowRepository();
  useEffect(() => {
    cashFlowRepo.auditOverdueCell(props.rowId).then(res => setData(res.data))
  }, [props.rowId])
  
  const columns: ColumnsType<any> = [
    {
      title: 'Número Mov.',
      dataIndex: 'financialEntryId',
      key: 'financialEntryId',
    },
    {
      title: 'Filial',
      dataIndex: 'firmName',
      key: 'firmName',
    },
    {
      title: 'Centro de Custo',
      dataIndex: 'costCenterName',
      key: 'costCenterName',
    },
    {
      title: 'Conta Gerencial',
      dataIndex: 'costCenterName',
      key: 'costCenterName',
    },
    {
      title: '',
      key: 'show',
      dataIndex: 'financialEntry',
      render: (id ,row) => (
        <Button
          icon={<EyeOutlined />}
          type={'link'}
          onClick={() => props.onRowSelected(row)} size={'small'}
        />
      )
    }
  ]
  
  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={cashFlowRepo.loading}
    />
  )
}