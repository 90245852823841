import {Descriptions, Tabs} from "antd";
import {useEffect, useState} from "react";
import {FinancialItemEntity} from "../../../../domain/entity/financial-item.entity";
import {useFinancialItemRepository} from "../../../../domain/repository/financial-item.repository";

export function ShowFullFinancialItem(props: { itemId: number }) {
  const [item, setItem] = useState<FinancialItemEntity>();

  const financialItemRepo = useFinancialItemRepository();

  useEffect(() => {
    financialItemRepo.find(props.itemId.toString()).then(res => {
      setItem(res.data)
    })
  }, [props.itemId])

  return (
    <Descriptions bordered layout={'vertical'}>
      <Descriptions.Item label={'Item'}>
        {item?.description}
      </Descriptions.Item>
      <Descriptions.Item label={'Código'}>
        {item?.class?.group?.code}.{item?.class?.code}.{item?.code}
      </Descriptions.Item>
      <Descriptions.Item label={'Conta Gerencial'}>
        {item?.managerialAccount?.code} - {item?.managerialAccount?.description}
      </Descriptions.Item>
      <Descriptions.Item label={'Classe'}>
        {item?.class?.name}
      </Descriptions.Item>
      <Descriptions.Item label={'Categorias Orçamentárias'}>
        {item?.class?.budgetCategories?.map(item => ` - ${item.name}`).join(' , ')}
      </Descriptions.Item>
      <Descriptions.Item label={'Grupo'}>
        {item?.class?.group?.name}
      </Descriptions.Item>
    </Descriptions>
  )

  return (
    <Tabs>
      <Tabs.TabPane key={1} tab={'Item'}>
        <Descriptions bordered layout={'vertical'}>
          <Descriptions.Item label={'Nome'}>
            {item?.description}
          </Descriptions.Item>
          <Descriptions.Item label={'Código'}>
            {item?.code}
          </Descriptions.Item>
          <Descriptions.Item label={'Conta Gerencial'}>
            {item?.managerialAccount?.description}
          </Descriptions.Item>
        </Descriptions>
      </Tabs.TabPane>
      <Tabs.TabPane key={2} tab={'Classe'}>
        <Descriptions bordered layout={'vertical'}>
          <Descriptions.Item label={'Nome'}>
            {item?.class?.name}
          </Descriptions.Item>
          <Descriptions.Item label={'Código'}>
            {item?.class?.code}
          </Descriptions.Item>
          <Descriptions.Item label={'Conta Gerencial'}>
            {item?.class?.budgetCategories?.map(item => item.name).join(',')}
          </Descriptions.Item>
        </Descriptions>
      </Tabs.TabPane>
      <Tabs.TabPane key={3} tab={'Grupo'}>
        <Descriptions bordered layout={'vertical'}>
          <Descriptions.Item label={'Nome'}>
            {item?.class?.group?.name}
          </Descriptions.Item>
          <Descriptions.Item label={'Código'}>
            {item?.class?.group?.code}
          </Descriptions.Item>
        </Descriptions>
      </Tabs.TabPane>
    </Tabs>
  )
}